import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Nav, Image, Card } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ApplyNow from '../ApplyNow';
import CareerBanner from '../../images/banners/CurrentOpenings1-banner.jpg';
import referBanner from '../../images/banners/Header.jpg';
import ReferralCarousel from '../Openings/referralCarousel';
import CustomPost from './CustomPost';

const TITLE = "Openings - XL Dynamics India Pvt. Ltd"
var empID = ""

class Openings extends Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : false,
            openingData : [],
            employeeData: [],
            openingCode : this.props.match.params.OpeningName,
            openingName: "",
            params : {},
            visible: true
        };
        this.getParams = this.getParams.bind(this)
        this.CSVToJSON = this.CSVToJSON.bind(this)
        this.CSVToArray = this.CSVToArray.bind(this)
    }
    
    componentDidMount() {
        fetch(process.env.REACT_APP_CURRENT_OPENINGS_CDN_URL)
        .then( response => response.json())
        .then(
            // handle the result
            (result) => {
                this.setState({
                    isLoaded : true,
                    openingData : result
                });
                let profileName = true;
                result.map(Openings => {
                    if(Openings.openingCode === decodeURIComponent(this.state.openingCode)) {
                        this.setState({visible: Openings.visible})
                        profileName = false;
                    }
                })
                profileName && this.setState({visible: false});
            }
        )
        empID = this.getParams(window.location.href)
        if(this.props.location.search.length > 0){
            document.body.classList.add("refer-class")
        }
        
        this.getCsvData();
        // var data = []
        // fetch("https://xld-cdn-bucket1.xldynamics.com/StudentEmployee.csv")
        // .then(response => response.json())
        // .then(
        //     (result) => {
        //         data = result.filter(function(val) {
        //            return val.referID == atob(empID.emp)
        //         })
        //         this.setState({
        //             employeeData: data
        //         })
                
        //     }
        // )

    }

    fetchCsv() {
        return fetch('https://xld-cdn-bucket1.xldynamics.com/StudentEmployee.csv').then(function (response) {

            let reader = response.body.getReader();
            let decoder = new TextDecoder('utf-8');

            return reader.read().then(function (result) {
                return decoder.decode(result.value);
            });
        });
    }

    // getData(result) {
    //     this.setState({employeeData: result.data});
    // }

    CSVToJSON(csvData) {
        var data = this.CSVToArray(csvData);
        var objData = [];
        for (var i = 1; i < data.length; i++) {
            objData[i - 1] = {};
            for (var k = 0; k < data[0].length && k < data[i].length; k++) {
                var key = data[0][k];
                objData[i - 1][key] = data[i][k]
            }
        }
        // var jsonData = JSON.stringify(objData);
        // jsonData = jsonData.replace(/},/g, "},\r\n");
        // data = objData.filter(function(val) {
        //         return val.referID == atob(empID.emp)
        // })
        if(empID.emp != undefined) {
            this.setState({
                employeeData: objData.filter(function(val) {
                                    return val.referID == atob(empID.emp)
                                })
            })
        }
        // this.setState({employeeData: objData});
        // return jsonData;
    }

    CSVToArray(csvData, delimiter) {
        delimiter = (delimiter || ",");
         var pattern = new RegExp((
        "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
        "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
        "([^\"\\" + delimiter + "\\r\\n]*))"), "gi");
        var data = [[]];
        var matches = null;
        while (matches = pattern.exec(csvData)) {
            var matchedDelimiter = matches[1];
            if (matchedDelimiter.length && (matchedDelimiter != delimiter)) {
                data.push([]);
            }
            if (matches[2]) {
                var matchedDelimiter = matches[2].replace(
                new RegExp("\"\"", "g"), "\"");
            } else {
                var matchedDelimiter = matches[3];
            }
            data[data.length - 1].push(matchedDelimiter);
        }
        return (data);
    }

    async getCsvData() {
        let csvData = await this.fetchCsv();
        this.CSVToJSON(csvData)
    }

    getParams(url) {
        var params = {};
        var parser = document.createElement('a');
        parser.href = url;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
        }

        return params;
    };
    

    selectChange(event){
        const url = window.location.origin+"/Opening/"+ event.target.value + '?emp='+ empID.emp
        window.location = url
    }

    render() {
        // const {
        //     params: {openingCode}
        // } = match;
        const {openingData, employeeData} = this.state;
        const jobDetails = openingData.map(Openings => {
            if(Openings.openingCode === decodeURIComponent(this.state.openingCode)) {
                // const setOpeningName = () => {
                    // this.setState({openingName : Openings.title})
                // }
                // setOpeningName();
                return <div key={Openings.openingId}> 
                    {Openings.openingCode === "chief-information-officer" ? 
                    <div>
                        <p>XL Dynamics has been providing financial services and solutions to the US Mortgage Banking Industry for the past 20 Years. We are 2300+ employees strong and have expanded our operations PAN India with Branches in Mumbai, Navi Mumbai, Pune, Bengaluru, Noida, Chennai, Ahmedabad, Indore, Lucknow, Mysuru, Kochi and Guwahati. We're still growing.</p>
                        <p>We're recruiting the best Engineers and Innovators to design and develop, industry-leading technology for the US Mortgage Industry. We don't have compensation limits for the best candidates.</p>
                        <p>Take our Online Assessment now by <a href="https://tests.mettl.com/authenticateKey/21skoaosg0" target="_blank">clicking here</a> to apply for this position. We're excited to meet you! </p>
                        <p>Read more about the job description, below:</p>
                    </div> 
                    : ''                        
                    }                    
                    {this.props.location.search.length > 0 ? 
                    <div className="about-title">
                        <h2>Position: {Openings.openingTitle} </h2>
                    </div> : ''}
                    {this.state.openingCode == "corporate-communications" || this.state.openingCode == "journalist" || this.state.openingCode == "corporate-trainer" || this.state.openingCode == "vertical-database-management-officer" || this.state.openingCode == "Creative-head" || this.state.openingCode == "Creative-lead" || this.state.openingCode == "AI-Engineer" ? 
                    <CustomPost props={this.props} openingCode={this.state.openingCode} /> :
                    <ul>
                        <li className="hide-list"><span style={{display: "inline"}} className="themeTextRed"><strong>Position:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.openingTitle}}></span></li>
                        {Openings.shiftTiming==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Job Shift:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.shiftTiming}}></span></li>}
                        {Openings.experience==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Experience:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.experience}}></span></li>}
                        {Openings.education==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Education:</strong> </span>
                        {/* <span dangerouslySetInnerHTML={{__html: Openings.education}}></span> */}
                        {Openings.openingCode === "chief-information-officer" || Openings.openingCode === "senior-software-developer" ?
                            <span>We hire based on your intelligence, ability to design and innovate, and work ethic. Formal degrees are not material in our hiring decision.</span> :
                            Openings.openingCode === 'corporate-communications' ?
                            <span>Experience and education in any relevant field such as literature, teaching and foreign language is preferred. However, note that we care less about experience or education. We look for passion.</span>:
                            Openings.openingCode === "Property-Manager" ? <span dangerouslySetInnerHTML={{__html: Openings.education}}></span> :
                            <span>We hire based on individual talent, skill and work ethic. Formal degrees are not a material in our hiring decision.</span>
                        }
                        </li>}
                        {Openings.jobLocation==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Job Location:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.jobLocation}}></span></li>}
                        {Openings.salaryRange==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Salary Range:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.salaryRange}}></span></li>}
                        {Openings.daysOfWalkIn==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Days of Walk-in:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.daysOfWalkIn}}></span></li>}
                        {Openings.timeOfWalkIn==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Time for Walk-in:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.timeOfWalkIn}}></span></li>}
                        {Openings.AdministrativeLocation != "" && Openings.openingCode == "Property-Manager" && <li><span  style={{display: "inline"}} className="themeTextRed"><strong>Administrative Location:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.AdministrativeLocation}}></span></li>}
                        {Openings.FunctionalLocation!= "" && Openings.openingCode == "Property-Manager" && <li><span  style={{display: "inline"}} className="themeTextRed"><strong>Functional Location:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.FunctionalLocation}}></span></li>}
                        {Openings.PreferredIndustry!= "" && Openings.openingCode == "Property-Manager" && <li><span  style={{display: "inline"}} className="themeTextRed"><strong>Preferred Industry:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.PreferredIndustry}}></span></li>}
                        {Openings.SpecialNote!= "" && Openings.openingCode == "Property-Manager" && <li><span  style={{display: "inline"}} className="themeTextRed"><strong>Special Note:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.SpecialNote}}></span></li>}

                        {this.props.location.search.length > 0 ? <li><span style={{display: "inline"}} className="themeTextRed"><strong>Key Responsibilities:</strong></span> 
                        {Openings.openingCode == "Property-Manager" && <p>This role will be independently responsible for managing 75+ properties in various states of US and all the works related to it as per description given below. </p>}
                        <div dangerouslySetInnerHTML={{__html: Openings.keyResponsibilities}}></div></li> : ''}
                        {this.props.location.search.length > 0 && Openings.UniqueOpportunities && Openings.UniqueOpportunities != "" ? <li><span style={{display: "inline"}} className="themeTextRed"><strong>UNIQUE OPPORTUNITIES:</strong></span> <div dangerouslySetInnerHTML={{__html: Openings.UniqueOpportunities}}></div></li> : ''}
                        {this.props.location.search.length > 0 && Openings.InnovationDynamics && Openings.InnovationDynamics != "" ? <li><span style={{display: "inline"}} className="themeTextRed"><strong>INNOVATION DYNAMICS:</strong></span> <div dangerouslySetInnerHTML={{__html: Openings.InnovationDynamics}}></div></li> : ''}
                        {this.props.location.search.length > 0 && Openings.openingCode === "chief-information-officer" ?
                                <li><span style={{display: "inline"}} className="themeTextRed"><strong>DESIRED PROFILE:</strong></span> 
                                <div>
                                    <ul>
                                    {Openings.experience==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Experience:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.experience}}></span></li>}
                                    {Openings.Innovation==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Innovation:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.Innovation}}></span></li>}
                                    {Openings.education==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Education:</strong> </span>{/* <span dangerouslySetInnerHTML={{__html: Openings.education}}></span> */}<span>We hire based on individual talent, skill and work ethic. Formal degrees are not a material in our hiring decision.</span></li>}
                                    </ul>
                                </div></li> 
                            : ''
                        }
                        {/* <br></br> */}
                        {this.props.location.search.length > 0 && Openings.openingCode === "senior-software-developer" ? <span style={{display: "inline"}} className="themeTextRed"><strong>If you are looking to make a difference, this is the place to be. If you are just looking for a job, do not apply here.</strong></span> : ''}
                        <br></br> 
                        {this.props.location.search.length > 0 && (Openings.openingCode === "Officer-Information-Security" || Openings.openingCode == "Property-Manager" || Openings.openingCode == "it-helpdesk-Executive"  || Openings.openingCode == "calling-specialists-voice" || Openings.openingCode == "junior-analyst" || Openings.openingCode == "internship" || Openings.openingCode == "call-quality-Specialist" || Openings.openingCode == "Social-Media-Specialist" || Openings.openingCode == "AWS-Lambda-Developer" || Openings.openingCode == "payroll-analyst" || Openings.openingCode == "offshore-accounting-audit" || Openings.openingCode == "financialAnalyst")? <li><span style={{display: "inline"}} className="themeTextRed"><strong>SKILLS/EXPERTISE:</strong></span> <div dangerouslySetInnerHTML={{__html: Openings.skillsNExpertise}}></div></li> : ''}
                    </ul>}    
                    {(this.state.openingCode == "associate-analyst" || Openings.openingCode == "AWS-Lambda-Developer" || Openings.openingCode == "Social-Media-Specialist") && 
                     <div className='p-2 pb-4' dangerouslySetInnerHTML={{__html: Openings.brifAboutProfile}}></div>
                    }              
                    <Card.Body fluid="true" className="customBorder hide-refer">
                            <span style={{display: "inline"}} className="themeTextRed"><strong>Key Responsibilities:</strong> </span>
                            {Openings.openingCode == "Property-Manager" && <p>This role will be independently responsible for managing 75+ properties in various states of US and all the works related to it as per description given below. </p>}
                            <div dangerouslySetInnerHTML={{__html: Openings.keyResponsibilities}}></div>
                            {/* && Openings.openingCode === "senior-software-developer" */}
                            {Openings.UniqueOpportunities && Openings.UniqueOpportunities != "" ? 
                                <>
                                <span style={{display: "inline"}} className="themeTextRed"><strong>Unique Opportunities:</strong> </span>
                                <div dangerouslySetInnerHTML={{__html: Openings.UniqueOpportunities}}></div>                                
                                </>
                            : ''}
                            {Openings.InnovationDynamics && Openings.InnovationDynamics != "" ? 
                                <>
                                <span style={{display: "inline"}} className="themeTextRed"><strong>Innovation Dynamics:</strong> </span>
                                <div className="themeTextRed" dangerouslySetInnerHTML={{__html: Openings.InnovationDynamics}}></div> </>
                            : ''}
                            {Openings.opportunityOffer && Openings.opportunityOffer != "" ? 
                                <>
                                <span style={{display: "inline"}} className="themeTextRed"><strong>WHAT DOES THIS OPPORTUNITY OFFERS?:</strong> </span>
                                <div dangerouslySetInnerHTML={{__html: Openings.opportunityOffer}}></div> </>
                            : ''}
                           {/* <br></br> */}
                            {Openings.openingCode === "chief-information-officer" ?
                                <li><span style={{display: "inline"}} className="themeTextRed"><strong>DESIRED PROFILE:</strong></span> 
                                <div>
                                    <ul>
                                    {Openings.experience==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Experience:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.experience}}></span></li>}
                                    {Openings.Innovation==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Innovation:</strong> </span><span dangerouslySetInnerHTML={{__html: Openings.Innovation}}></span></li>}
                                    {Openings.education==="" ? "" : <li><span style={{display: "inline"}} className="themeTextRed"><strong>Education:</strong> </span>
                                    {/* <span dangerouslySetInnerHTML={{__html: Openings.education}}></span> */}
                                    {Openings.openingCode === "chief-information-officer" ?
                                        <span>We hire based on your intelligence, ability to design and innovate, and work ethic. Formal degrees are not material in our hiring decision.</span> :
                                        <span>We hire based on individual talent, skill and work ethic. Formal degrees are not a material in our hiring decision.</span>
                                    }                                    
                                    </li>}
                                    </ul>
                                </div></li> 
                            : ''
                        }
                        {Openings.openingCode === "senior-software-developer" ? 
                        <span style={{display: "inline"}} className="themeTextRed"><strong>If you are looking to make a difference, this is the place to be. If you are just looking for a job, do not apply here.</strong></span>
                        : ''
                        }
                               
                    </Card.Body> 
                    <br></br>    
                    {(Openings.openingCode === "Officer-Information-Security" || Openings.openingCode == "Property-Manager" || Openings.openingCode == "it-helpdesk-Executive" || Openings.openingCode == "calling-specialists-voice" || Openings.openingCode == "junior-analyst" || Openings.openingCode == "internship" || Openings.openingCode == "call-quality-Specialist" || Openings.openingCode == "Social-Media-Specialist" || Openings.openingCode == "AWS-Lambda-Developer" || Openings.openingCode == "payroll-analyst" || Openings.openingCode == "offshore-accounting-audit" || Openings.openingCode == "financialAnalyst") &&
                    <Card.Body fluid="true" className="customBorder hide-refer">
                                <span style={{display: "inline"}} className="themeTextRed"><strong>SKILLS/EXPERTISE:</strong> </span>
                                <div dangerouslySetInnerHTML={{__html: Openings.skillsNExpertise}}></div>
                    </Card.Body>}
                       
                        {
                        this.props.location.search.length > 0 ? 
                            <div className="refer-container">                               
                                <strong className="refer-title">IF YOU ARE LOOKING TO MAKE A DIFFERENCE, THIS IS THE PLACE TO BE</strong>
                                
                                    <Card.Body fluid="true" className="customBorder referCustomBorder"> 
                                    <strong className="refer-title" style={{color:"#C01722", fontSize: "22pt"}}> Contact Information</strong>
                                        <ApplyNow match={this.props.match} location={this.props.location} employeeID={empID} />
                                    </Card.Body>
                               
                            </div> : ''}
                    {this.state.openingCode == "associate-analyst" && 
                        <div className='p-2' dangerouslySetInnerHTML={{__html: Openings.weOffer}}></div>
                    }
                    {this.state.openingCode == "Creative-head" || this.state.openingCode == "Creative-lead" ? <></> :
                    <Card.Body fluid="true">
                        <span style={{display: "inline"}} className="themeTextRed"><strong>Note:</strong> </span>
                        <div dangerouslySetInnerHTML={{__html: Openings.notes}}></div>
                    </Card.Body> }
                </div>;
            }
        });
        var onlineTest = ""
            var uri = ""
            if(decodeURIComponent(this.state.openingCode) === "senior-software-developer"){  
                uri="https://tests.mettl.com/authenticateKey/21skoaosg0"
                onlineTest = <h5 style={{lineHeight:"1.6"}}>SALARY UP TO Rs. 50,00,000 PER ANNUM!! YOU CAN WORK FROM HOME TOO! <br/> 
                                <strong  className="themeTextRed" style={{fontSize:"17px"}}>Are you ready to write software that is changing the course of a multi-trillion dollar fin-tech industry? &nbsp;</strong>
                               <div style={{fontSize:"15px"}}>Click on the link to take this self-assessment <a href={uri} className="btn btn-sm btn-danger" target="_blank" style={{display:"unset"}}>Online Test</a></div>                               
                            </h5>
            } else if(decodeURIComponent(this.state.openingCode) === "offshore-accounting"){
                uri="https://tests.mettl.com/authenticateKey/210ux81fcw"
                onlineTest = <h5 style={{lineHeight:"1.6"}}>SALARY UP TO Rs. 4,00,000 PER ANNUM!! YOU CAN WORK FROM HOME TOO!<br/> 
                                <span style={{fontSize:"15px"}}>Take a quick Online Test to see if you can meet our expectations. Only the best are successful. See how good you are by attempting the online test. &nbsp;</span>
                                <a href={uri} className="btn btn-sm btn-danger" target="_blank" style={{display:"unset"}}>Online Test</a>
                            </h5>
            } else if(decodeURIComponent(this.state.openingCode) === "front-end-developer"){
                uri="https://tests.mettl.com/authenticateKey/21skoaosg0"
                onlineTest = <h5 style={{lineHeight:"1.6"}}>SALARY UP TO Rs. 15,00,000 PER ANNUM!! YOU CAN WORK FROM HOME TOO! <br/> 
                                <span style={{fontSize:"15px"}}>Take a quick Online Test to see if you can meet our expectations. Only the best are successful. See how good you are by attempting the online test. &nbsp;</span>
                                <a href={uri} className="btn btn-sm btn-danger" target="_blank" style={{display:"unset"}}>Online Test</a>
                            </h5>
            }
            
       var ApplyNowComponent = {}
       if(decodeURIComponent(this.state.openingCode) === "senior-software-developer" || decodeURIComponent(this.state.openingCode) === "offshore-accounting" || decodeURIComponent(this.state.openingCode) === "front-end-developer"){
        ApplyNowComponent = <div className="text-right" key={Openings.openingId}>
                        {onlineTest}
                </div>;
            }
        if(Object.keys(ApplyNowComponent).length == 0){
            ApplyNowComponent = openingData.map(Openings => {           
                if(Openings.openingCode === decodeURIComponent(this.state.openingCode)) {
                    return <div className="text-right" key={Openings.openingId}>
                                <h5 style={{lineHeight:"1.6"}}>Take Your <span className="themeTextRed">Career</span> to the Next level with XLD!&nbsp;&nbsp;                            
                                <Nav.Link as={Link} to={`/ApplyNow/${Openings.openingCode}`} className="btn btn-danger btn-sm" style={{display:"unset"}}>Apply Now</Nav.Link>
                                </h5>
                            </div>;
                }
            });
        }        

        const employeePanel = employeeData.map(emp =>{
            if(emp.applicantType.toLowerCase() == "employee"){
                return <div className="emp-panel row">
                    <Col xs={12} sm={4}>
                        <div className="pers-panel">
                            <img src={emp.imagePath}></img>
                            <span className="name"> {emp.referName}</span>
                            <span  className="team">{emp.team}</span>
                            <span  className="year">{emp.years}+ YEARS</span>
                        </div>
                    </Col>
                    <Col xs={12} sm={8}>
                    <div className="emp-info"><span>{emp.information}</span></div>
                    </Col>
                </div>
            } else if(emp.applicantType.toLowerCase() == "student"){
                return <strong>A Warm Welcome to the Students of <span>{emp.referName}</span> </strong>
            }                
        });
        
        const openingTitle = openingData.map(Openings => {
             if(Openings.openingCode === decodeURIComponent(this.state.openingCode)) {
                return <h1 className="x-head" style={{height:"50px"}} key={Openings.openingId}>
                    {Openings.openingCode === 'sr-Content-specialist' ? 
                    <>Senior Content Specialist <span>(Full-Time / Part-Time / Consultant)</span></>
                    : Openings.openingTitle}
                    </h1>
            }
        });

        const openingsList = openingData.map(oData => {
            let title = oData.openingTitle;
            if(oData.visible == 1) {
                // if(oData.openingCode != 'Content-specialist' && oData.openingCode != 'corporate-communications') {
                    return <option key={oData.openingId} value={oData.openingCode}>{title}</option>;
                // }
            }
        });

        return(
            <React.Fragment>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                    <Row className="removeMarginLR">
                        {
                            this.props.location.search.length > 0 ?
                            // <Image className="d-block w-100" style={{padding:"10px"}} src={referBanner} fluid /> 
                            <ReferralCarousel/>
                            :
                            // <ReferralCarousel/>
                            <></>
                            // <Image className="d-block w-100" style={{padding:"10px"}} src={CareerBanner} fluid />
                        }
                        <Col xs={1}></Col>
                        <Col xs={12} sm={12} md={10}>
                            {
                                this.props.location.search.length > 0 ?
                                <div className="refer-container">
                                    <div className="info-container">
                                        {employeePanel}
                                    </div>
                                    <div className="about-title">
                                        <h2>About XL Dynamics</h2>
                                        <p>
                                            XL Dynamics India Pvt. Ltd. provides end-to-end Mortgage Process Outsourcing solution to clients. We have proven expertise to
                                            support processes across various parts of mortgage value chain from loan processing through secondary market portfolio
                                            management and servicing. Our experience in financial services and the mortgage banking industry, with a blend of technology
                                            and our focus on providing the highest quality financial services to our clients in US from our operation centers in India; enables
                                            us to provide the most efficient and cost effective services to our customers. With over 15 years in the Mortgage Process
                                            Outsourcing Industry and more than 2,800 employees as of Jan 2021, we have grown to become one of the leading service
                                            providers in industry.                                        
                                        </p>
                                    </div>
                                    <div className="about-title">
                                    <h2>Corporate office</h2>
                                    <Row className="address-panel">
                                            <Col className="address" xs={12}>
                                                <strong>Navi Mumbai</strong>
                                                <span>
                                                Block No. 301/302<br/>
                                                Building V, Sector II<br/>
                                                Millennium Business Park, Mahape, <br/>
                                                Navi Mumbai, Maharashtra - 400710<br/>
                                                </span>
                                            </Col>
                                    </Row>
                                    </div>

                                    <div className="role-panel">
                                        <strong>SELECT JOB POSITIONS YOU ARE INTERESTED IN</strong>
                                        <select name="select" class="form-control" onChange={this.selectChange} required>
                                            <option key="Select role" value="Select role" selected={true}>Select role</option> 
                                            {openingsList}
                                        </select>
                                    </div>
                                    {this.state.visible ? jobDetails : <h2 className="text-center">WE ARE SORRY.<br/>THE PROFILE YOU REQUESTED WAS NOT FOUND.</h2> }
                                    {/* <strong className="refer-title">IF YOU ARE LOOKING TO MAKE A DIFFERENCE, THIS IS THE PLACE TO BE</strong>
                                    {
                                        this.props.location.search.length > 0 ?
                                        <Card.Body fluid="true" className="customBorder"> 
                                        <strong className="refer-title" style={{color:"#C01722", fontSize: "22pt"}}> Contact Information</strong>
                                            <ApplyNow match={this.props.match} location={this.props.location}/>
                                        </Card.Body>

                                        : ''
                                    } */}
                                </div> 
                                : 
                                this.state.visible ?
                                <div>
                                    {openingTitle}
                                    {ApplyNowComponent}<br/>
                                    {this.state.openingCode == "corporate-communications" || this.state.openingCode == "journalist" || this.state.openingCode == "corporate-trainer" || this.state.openingCode == "vertical-database-management-officer" || this.state.openingCode == "Creative-head" || this.state.openingCode == "Creative-lead" || this.state.openingCode == "AI-Engineer" ? 
                                    <div>
                                        <CustomPost props={this.props} openingCode={this.state.openingCode}/>
                                        {this.state.openingCode == "Creative-head" || this.state.openingCode == "Creative-lead" || this.state.openingCode == "AI-Engineer" ? <></> : <>
                                        <span style={{display: "inline"}} className="themeTextRed"><strong>Note:</strong></span>
                                        <ul>
                                            <li>Candidates who have appeared for our selection process in the last 3 months are not eligible for this opening.</li>
                                            <li>Candidates with notice period of more than 60 days (non-negotiable), are requested not to apply.</li>
                                        </ul><br></br></>}
                                    </div> : <div>{jobDetails}</div>}
                                    {ApplyNowComponent}
                                    {this.state.openingCode === "senior-software-developer" ?
                                        <Card.Body fluid="true">
                                                <div><strong>Note:</strong> You can complete this self-assessment in the programming language of your choice. If you use a high level language (e.g. C#, Java, python, javascript, etc,), then, 
                                                    prior to attempting this self-assessment exercise, brush up on your understanding of lists, arrays, matrices, and string parsing functions like split.</div>
                                        </Card.Body> : ''
                                    }
                                </div>
                                : <h1 className="text-center no-profile">WE ARE SORRY.<br/>BUT THE PAGE YOU REQUESTED WAS NOT FOUND.</h1>                  
                            }                       
                        </Col>
                        <Col xs={1}></Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default Openings;